export enum InitiatorActions {
  UPDATE_INITIATOR_PENDING = 'UPDATE_INITIATOR_PENDING',
  UPDATE_INITIATOR_SUCCESS = 'UPDATE_INITIATOR_SUCCESS',
  UPDATE_INITIATOR_ERROR = 'UPDATE_INITIATOR_ERROR',

  ACTIVATE_INITIATOR_PENDING = 'ACTIVATE_INITIATOR_PENDING',
  ACTIVATE_INITIATOR_SUCCESS = 'ACTIVATE_INITIATOR_SUCCESS',
  ACTIVATE_INITIATOR_ERROR = 'ACTIVATE_INITIATOR_ERROR',

  BOOTSTRAP_INITIATORS = 'BOOTSTRAP_INITIATORS',

  GET_INITIATORS_PENDING = 'GET_INITIATORS_PENDING',
  GET_INITIATORS_SUCCESS = 'GET_INITIATORS_SUCCESS',
  GET_INITIATORS_ERROR = 'GET_INITIATORS_ERROR',

  GET_INITIATOR_PENDING = 'GET_INITIATOR_PENDING',
  GET_INITIATOR_SUCCESS = 'GET_INITIATOR_SUCCESS',
  GET_INITIATOR_ERROR = 'GET_INITIATOR_ERROR',

  DELETE_INITIATOR_PENDING = 'DELETE_INITIATOR_PENDING',
  DELETE_INITIATOR_SUCCESS = 'DELETE_INITIATOR_SUCCESS',
  DELETE_INITIATOR_ERROR = 'DELETE_INITIATOR_ERROR',
}
