import { Action } from 'redux';
import { InitiatorActions } from 'src/actions/initiators/initiators.action-types';
import { UPDATE_CURRENT_APP_PENDING, UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';

import { initialState, InitiatorsState } from './initiators.state';
import { InitiatorItem } from './initiators.types';

export type InitiatorsReducerAction = Action<
  InitiatorActions | typeof UPDATE_CURRENT_APP_PENDING | typeof UPDATE_CURRENT_APP_SUCCESS
> & {
  payload: {
    initiator?: InitiatorItem;
    initiators?: InitiatorItem[];
    initiatorId?: string;
    appId?: string;
    errorMessage?: string;
  };
  error?: Error;
};

export const initiatorsReducer = (
  state: InitiatorsState = initialState,
  action: InitiatorsReducerAction = {} as InitiatorsReducerAction,
): InitiatorsState => {
  switch (action.type) {
    case UPDATE_CURRENT_APP_PENDING:
    case UPDATE_CURRENT_APP_SUCCESS: {
      return {
        ...state,
        ...initialState,
      };
    }
    case InitiatorActions.GET_INITIATORS_PENDING: {
      return { ...state, loading: true };
    }
    case InitiatorActions.GET_INITIATORS_SUCCESS: {
      const { initiators = [] } = action.payload;
      return {
        ...state,
        initiators,
        loading: false,
      };
    }

    case InitiatorActions.GET_INITIATORS_ERROR: {
      return { ...state, loading: false };
    }

    case InitiatorActions.ACTIVATE_INITIATOR_PENDING: {
      const { initiatorId = '' } = action.payload;
      return {
        ...state,
        activatingIds: [...state.activatingIds, initiatorId],
      };
    }

    case InitiatorActions.GET_INITIATOR_PENDING: {
      const { initiatorId = '' } = action.payload;
      return {
        ...state,
        loadingIds: [...state.loadingIds, initiatorId],
      };
    }

    case InitiatorActions.GET_INITIATOR_SUCCESS:
    case InitiatorActions.UPDATE_INITIATOR_SUCCESS:
    case InitiatorActions.ACTIVATE_INITIATOR_SUCCESS: {
      const { initiator } = action.payload as { initiator: InitiatorItem };
      const data = [...state.initiators];
      const idx = data.findIndex((item) => item.id === initiator.id);
      if (idx >= 0) {
        data.splice(idx, 1, initiator);
      } else {
        data.unshift(initiator);
      }
      return {
        ...state,
        initiators: data,
        loadingIds: state.loadingIds.filter((id) => id !== initiator.id),
        activatingIds: state.activatingIds.filter((id) => id !== initiator.id),
      };
    }

    case InitiatorActions.GET_INITIATOR_ERROR: {
      const { initiatorId = '' } = action.payload;
      return {
        ...state,
        loadingIds: state.loadingIds.filter((id) => id !== initiatorId),
        activatingIds: state.activatingIds.filter((id) => id !== initiatorId),
      };
    }

    case InitiatorActions.DELETE_INITIATOR_SUCCESS: {
      const { initiatorId = '' } = action.payload;
      return {
        ...state,
        initiators: state.initiators.filter((item) => item.id !== initiatorId),
      };
    }

    default:
      return state;
  }
};
