import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import numeral from 'numeral';
import moment from 'moment';
import md5 from 'md5';

import { daysAgo } from 'src/utils/time';
import findDomain from 'src/utils/find_domain';
import Series from './series';
import GraphContainer from './container';
import HoverOverlay from './hover_overlay';

import '../styles/graphs/graph.scss';
import '../styles/loading_animations.scss';

export default class TimeSeries extends Component {
  state = {
    width: this.props.minWidth,
    tooltipVisible: false,
  };

  dots = [];
  recticles = [];
  tooltips = [];

  static propTypes = {
    fetchDataCB: PropTypes.func,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    titleLabel: PropTypes.node,
    className: PropTypes.string,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.number,
        y: PropTypes.number,
      })
    ),
    referenceLines: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.number,
      })
    ),
    numTicks: PropTypes.number,
    xMargin: PropTypes.number,
    yMargin: PropTypes.number,
    height: PropTypes.number,
    minWidth: PropTypes.number,
    loading: PropTypes.bool,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    yBase: PropTypes.number,
    fetchError: PropTypes.bool,
    summary: PropTypes.string,
    labelMap: PropTypes.instanceOf(Object),
    staticYRange: PropTypes.arrayOf(PropTypes.number),
    staticXRange: PropTypes.bool,
    hoverTitle: PropTypes.string,
    hoverDateFormat: PropTypes.func,
    hoverValuesFormat: PropTypes.func,
    hoverDetailsFormat: PropTypes.func,
    hoverSummaryFormat: PropTypes.func,
    platform: PropTypes.string,
    percentTicks: PropTypes.bool,
    isLegacy: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    xMargin: 0,
    height: 154,
    minWidth: 0,
    yMargin: 0,
    loading: false,
    series: [],
    referenceLines: [],
    numTicks: 5,
    yBase: 10,
    fetchError: false,
    fetchDataCB: () => undefined,
    labelMap: {},
    hoverValuesFormat: v => numeral(v).format('0.[0]a'),
    hoverDetailsFormat: _v => null,
    hoverSummaryFormat: () => '',
    title: '',
    subtitle: '',
    titleLabel: <span />,
    startDate: daysAgo(30).valueOf(),
    endDate: new Date().valueOf(),
    summary: '',
    staticYRange: [],
    staticXRange: false,
    hoverTitle: '',
    hoverDateFormat: (v) => moment.utc(v).format('MMM D, YYYY'),
    platform: '',
    percentTicks: false,
    isLegacy: true,
  };

  componentDidMount() {
    this.resizeGraph();
    window.addEventListener('resize', this.resizeGraph);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeGraph);
  }

  resizeGraph = () => {
    const clientWidth = this.inputNode ? this.inputNode.clientWidth : 0;
    this.setState({
      width: Math.max(this.props.minWidth, clientWidth),
    });
  };

  render() {
    const { series, titleLabel, numTicks, referenceLines, xMargin, height, yMargin, startDate, endDate, yBase, summary, staticYRange, staticXRange, percentTicks, ...rest } = this.props;
    const platform = this.props.platform.toLocaleLowerCase();
    const width = this.state.width;

    const graphWidth = width - (xMargin * 2);
    const graphHeight = height - (yMargin * 2);
    // Compute max reference lines on each access in case they end up being one
    // of the extents.
    const xAxisReferences = referenceLines.filter(ref => typeof ref.date !== 'undefined');
    const yAxisReferences = referenceLines.filter(ref => typeof ref.y !== 'undefined');
    const maxXAxisReferences = Math.max(...xAxisReferences.map(ref => ref.date));
    const minYAxisReferences = Math.min(...yAxisReferences.map(ref => ref.y), 0); // 0, to fix float X axis, (KIE-209)
    const maxYAxisReferences = Math.max(...yAxisReferences.map(ref => ref.y));
    const datums = series.reduce((acc, s) => acc.concat(s.datums.map(d => d.date)), []);
    const maxXDate = Math.max(...datums);
    const minXDate = Math.min(...datums);

    const yAxisWidth = 25;

    let dateRange;
    if (staticXRange) {
      dateRange = [new Date(startDate), new Date(endDate)];
    } else if (series.length > 0) {
      dateRange = findDomain(series, 'date', maxXAxisReferences);
      dateRange[0] = new Date(dateRange[0] || startDate);
      dateRange[1] = new Date(dateRange[1] || endDate);
      if (maxXDate === minXDate) {
        dateRange = [
          moment.utc(minXDate).add(-3, 'day').toDate(),
          moment.utc(maxXDate).add(3, 'day').toDate(),
        ];
      }
    } else {
      dateRange = [new Date(startDate), new Date(endDate)];
    }

    const xScale = d3.scaleUtc()
      .domain(dateRange)
      .range([xMargin + yAxisWidth, graphWidth + xMargin]);

    let yRange;
    if (series.length > 0) {
      yRange = findDomain(series, 'y', maxYAxisReferences);
      yRange[0] = yRange[0] || 0;
      if (typeof staticYRange[0] !== 'undefined') {
        yRange[0] = staticYRange[0];
      } else if (minYAxisReferences < yRange[0]) {
        yRange[0] = minYAxisReferences;
      }
      yRange[1] = staticYRange.length > 1 ? staticYRange[1] : yRange[1] || yBase;
    } else {
      yRange = [0, yBase];
    }

    const yScale = d3.scaleLinear()
      .domain(yRange)
      .range([graphHeight + yMargin, yMargin])
      .nice(5);

    const yAxisReferenceLines = yAxisReferences.map(ref => ({
      label: ref.label,
      datums: [
        { date: xScale.domain()[0].valueOf(), y: ref.y },
        { date: xScale.domain()[1].valueOf(), y: ref.y },
      ],
    }));

    let lastTick;
    const tickSpacing = 75;
    const xTickValues = xScale.ticks(d3.utcDay.every(1)).filter((d) => {
      if (typeof lastTick === 'undefined') {
        lastTick = d;
        return true;
      }
      if (xScale(d) - xScale(lastTick) > tickSpacing) {
        lastTick = d;
        return true;
      }
      return false;
    });

    const xAxis = d3.axisBottom(xScale)
      .tickValues(xTickValues)
      .tickFormat(d3.utcFormat('%b %e'))
      .tickSize(10)
      .tickSizeOuter(0);

    const xAxisReferenceLines = xAxisReferences
      .filter(ref => ref.date < dateRange[1] && ref.date > dateRange[0])
      .sort((refA, refB) => (moment.utc(refA.date).isBefore(moment.utc(refB.date)) ? -1 : 1))
      .map(ref => ({
        label: ref.label,
        customClass: ref.customClass,
        datums: [
          { y: yScale.domain()[0], date: ref.date },
          { y: yScale.domain()[1], date: ref.date },
        ],
      }))
      .reduceRight((refLines, xRef) => {
        if (refLines.length < 1) return [xRef];

        const lastRefDate = refLines[0].datums[0].date;
        const thisRefDate = new Date(xRef.datums[0].date);
        const minLabelDistance = 9;
        const thisRefXPos = xScale(thisRefDate);
        if (thisRefXPos < xScale(dateRange[0])) return refLines;

        if (xScale(lastRefDate) - xScale(thisRefDate) < minLabelDistance) {
          const labelCount = refLines[0].label.split('+').length;
          let newLabel;
          if (labelCount < 3) {
            newLabel = ` + ${xRef.label}`;
          } else if (labelCount === 3) {
            newLabel = ' + ...';
          } else {
            newLabel = '';
          }
          return [
            { ...refLines[0], label: `${refLines[0].label}${newLabel}` },
            ...refLines.slice(1),
          ];
        }
        return [
          xRef,
          ...refLines,
        ];
      }, []);

    const yAxisFormat = percentTicks
      ? d => numeral(d).format('0%')
      : d => numeral(d).format('0.[0]a');

    const yAxis = d3.axisRight(yScale)
      .ticks(numTicks)
      .tickSize(graphWidth)
      .tickFormat(yAxisFormat);

    const graph = (
      <svg
        width={width}
        height={height + 20}
        ref={(svg) => { this.svg = svg; }}
        className="timeseries-graph"
      >
        <g className="series">
          {series.map((s, i) => (
            <Series
              key={`series-${s.label}`}
              data={s.datums}
              xScale={xScale}
              yScale={yScale}
              order={i}
            />
          ))}
        </g>
        <g className="y-axis-reference-lines">
          {yAxisReferenceLines
            .filter(s => (s.datums[0].y > yRange[0] && s.datums[0].y < yRange[1])).map((s, i) => (
              <Series
                // TODO: Find better index option
                // eslint-disable-next-line react/no-array-index-key
                key={`y-axis-reference-line-${i}`}
                data={s.datums}
                xScale={xScale}
                yScale={yScale}
                order={i + series.length}
              />
            ))}
        </g>
        <g className="x-axis-reference-lines">
          {xAxisReferenceLines.map((s, i) => {
            if (s.datums[0].date < xScale.domain()[0] || s.datums[0].date > xScale.domain()[1]) return null;
            const customTextClass = s.customClass ? `${s.customClass}-text` : '';
            const customLineClass = s.customClass ? `${s.customClass}-line` : '';

            return (
              <g key={`x-axis-reference-line-timeseries-${md5(JSON.stringify(s))}`}>
                <text
                  x={`${xScale(s.datums[0].date) - 6}px`}
                  y={`${yMargin}px`}
                  className={customTextClass}
                >
                  {s.label}
                </text>
                <Series
                  data={s.datums}
                  xScale={xScale}
                  yScale={yScale}
                  order={i}
                  color={'#000000'}
                  customClass={customLineClass}
                />
              </g>
            );
          })}
        </g>
        <g
          className="x axis"
          transform={`translate(0, ${height - yMargin})`}
          ref={axis => d3.select(axis).call(xAxis)}
        />
        <g
          className="y axis"
          ref={axis => {
            d3.select(axis).call(yAxis);
            d3.select(axis).selectAll('line')
              .attr('class', (_d, i) => {
                if (i === 0) {
                  return 'origin';
                }
                return null;
              });
            d3.select(axis).selectAll('text')
              .attr('x', 2)
              .attr('dy', 11)
              .attr('font-weight', 'bold')
              .attr('class', (_d, i) => {
                if (i === 0) {
                  return 'origin';
                }
                return null;
              });
          }}
        />
        <g
          className="hover"
          style={{
            display: (this.state.tooltipVisible ? null : 'none'),
          }}
          ref={(hover) => { this.hoverTooltip = hover; }}
        >
          <g className="recticles">
            <line className="x" y1={0} y2={height} />
            {series.map((s) => (
              <g
                key={`recticle-${s.label}`}
                ref={(recticle) => { this.recticles[s.label] = recticle; }}
              >
                <circle
                  key={`hover-dot-${s.label}`}
                  ref={(dot) => { this.dots[s.label] = dot; }}
                  className="y"
                  r={4}
                />
                <line className="y" x1={width} x2={width} />
              </g>
            ))}
          </g>
        </g>
      </svg>
    );

    return (
      <GraphContainer
        inputRef={node => { this.inputNode = node; }}
        startDate={startDate}
        endDate={endDate}
        {...rest}
      >
        <div className="key">
          {this.props.isLegacy
            ? series.map((s, i) => (
              <div
                key={`label-${s.label}`}
                className={`label label-${i}`}
              >
                <div className={`key-color ${platform} series-${i}-color`} />
                {this.props.labelMap[s.label] || s.label}
              </div>
            ))
            : <>{titleLabel}</>
          }
          {yAxisReferenceLines.map((s, i) => (
            <div
              key={`label-${i + series.length}`}
              className={`label label-${i + series.length}`}
            >
              <div className={`key-color ${platform} series-${i + series.length}-color`} />
              {s.label}
            </div>
          ))}
        </div>
        <div className="summary">
          {summary}
        </div>
        <div className="graph-wrapper" style={{ position: 'relative' }}>
          {graph}
          <HoverOverlay
            className={`${this.props.className}__hover`}
            title={this.props.hoverTitle || this.props.title}
            forFormat={this.props.hoverDateFormat || d3.utcFormat('%b %e, %Y')}
            displayFormat={this.props.hoverValuesFormat}
            detailFormat={this.props.hoverDetailsFormat}
            summaryFormat={this.props.hoverSummaryFormat}
            mouseCoords={() => d3.mouse(this.svg)}
            coordinates={(xVal, yVal) => [xScale(xVal) - yAxisWidth, yScale(yVal)]}
            coordsToPoint={(xCoord, yCoord) => [xScale.invert(xCoord), yScale.invert(yCoord)]}
            closestPoints={(xPos, _yPos) => {
              // The closest points for each series
              const points = [];
              const bisectX = d3.bisector((d) => d.date).left;
              series.forEach((s) => {
                const data = s.datums;

                const dataXIndex = bisectX(data, xPos, 1);
                const dataLeft = data[dataXIndex - 1];
                const dataRight = data[dataXIndex];
                const hasLeftPoint = typeof dataLeft !== 'undefined';
                const hasRightPoint = typeof dataRight !== 'undefined';
                const hasLeftAndRightPoint = hasLeftPoint && hasRightPoint;

                let closestPoint;
                if (hasLeftAndRightPoint) {
                  closestPoint = (xPos - (dataLeft.date) > dataRight.date - xPos ? dataRight : dataLeft);
                } else if (hasLeftPoint) {
                  closestPoint = dataLeft;
                }

                if (!closestPoint || Number.isNaN(parseFloat(closestPoint.y))) {
                  return;
                }

                points.push({
                  label: this.props.labelMap[s.label] || s.label,
                  y: parseFloat(closestPoint.y),
                  x: closestPoint.date,
                });
              });
              return points;
            }}
          />
        </div>
      </GraphContainer>
    );
  }
}
