import { Rest, API } from 'src/api';
import { fetchLoveDialogEventsDemoData } from 'src/api/demo_util';
import { EventDistributionData } from 'src/types/core';
import { isDemoEnv } from 'src/utils/environment';
import { AppEventItem, AppEventList, SimpleEventItem } from 'src/reducers/events';
import demoData from 'src/utils/demo_data_generator';
import { BaseService } from './base-service';

export interface GetEventsResponse {
  data: Omit<EventDistributionData, 'count'>[];
}

export interface GetTargetingEvents {
  items: SimpleEventItem[];
}

export class EventStateService extends BaseService {
  static getLDEvents(appId: string, start: string, end: string, isPrevPeriod: boolean): Promise<GetEventsResponse> {
    if (isDemoEnv()) {
      return fetchLoveDialogEventsDemoData(start, end, isPrevPeriod);
    }

    return Rest.httpGet(API.EVENT_STATS.GET_PRIMARY_LD_EVENTS(appId, start, end), {
      errorTitle: '[API] Error Fetching Primary LD Events',
    }).promise;
  }

  static getEventsByIntId(
    appId: string,
    intId: string,
    start: string,
    end: string,
    query = '',
  ): Promise<GetEventsResponse> {
    return Rest.httpGet(API.EVENT_STATS.GET_EVENTS_BY_INTERACTION_ID(appId, intId, start, end, query), {
      errorTitle: '[API] Error Fetching LD Events By Interaction',
    }).promise;
  }

  static getTargetingEvents(
    appId: string,
    includeArchived: boolean,
    sortBy: string,
    sortDirection: string,
  ): Promise<GetTargetingEvents> {
    return Rest.httpGet(API.EVENT_STATS.GET_TARGETING_EVENTS(appId, includeArchived, sortBy, sortDirection), {
      errorTitle: '[API] Error Fetching Targeting Events',
    }).promise;
  }

  static getMultipleAppsTargetingEvents(
    orgId: string,
    apps: string[],
    includeArchived: boolean,
    sortBy: string,
    sortDirection: string,
  ): Promise<AppEventList[]> {
    return Rest.httpPost(
      API.EVENT_STATS.GET_MULTIPLE_APPS_TARGETING_EVENTS(orgId, includeArchived, sortBy, sortDirection),
      {
        body: { apps },
        errorTitle: '[API] Error Fetching Multiple Apps Targeting Events',
      },
    ).promise;
  }

  static fetchEvents(appId: string, queryString = ''): Promise<any> {
    return Rest.httpGet(API.EVENT_STATS.GET_EVENTS(appId, queryString), {
      errorTitle: '[API] Error Fetching Events',
    }).promise;
  }

  static fetchAllEvents(appId: string): Promise<any> {
    return Rest.httpGet(API.EVENT_STATS.GET_ALL_EVENTS(appId), {
      errorTitle: '[API] Error Fetching All Events',
    }).promise;
  }

  static createEvent(appId: string, label: string): Promise<any> {
    return Rest.httpPost(API.EVENT_STATS.CREATE_EVENT(appId), {
      body: { label },
      errorTitle: '[API] Error Creating Event',
    }).promise;
  }

  static createMultipleAppsEvent(orgId: string, apps: string[], label: string): Promise<AppEventItem[]> {
    return Rest.httpPost(API.EVENT_STATS.CREATE_MULTIPLE_APPS_EVENT(orgId), {
      body: { apps, label },
      errorTitle: '[API] Error Creating Multiple Apps Event',
    }).promise;
  }

  static updateEvent(appId: string, label: string, archive: boolean): Promise<any> {
    return Rest.httpPut(API.EVENT_STATS.UPDATE_EVENT(appId, label), {
      body: { archive },
      errorTitle: '[API] Error Updating Event',
    }).promise;
  }

  static deleteEvent(appId: string, label: string): Promise<any> {
    return Rest.httpDelete(API.EVENT_STATS.DELETE_EVENT(appId, label), {
      errorTitle: '[API] Error Deleting Event',
    }).promise;
  }

  static fetchEventsTimeseries(appId: string, labels: string[], start: number, end: number): Promise<any> {
    if (isDemoEnv()) {
      return Promise.resolve(demoData(start, end).SURVEYS_TIMESERIES);
    }

    return Rest.httpGet(API.EVENT_STATS.GET_EVENTS_TIMESERIES(appId, labels, start, end), {
      errorTitle: '[API] Error Fetching Events Timeseries',
    }).promise;
  }
}
