import { InitiatorItem } from './initiators.types';

export interface InitiatorsState {
  initiators: InitiatorItem[];
  loadingIds: string[];
  activatingIds: string[];
  loading: boolean;
}

export const initialState: InitiatorsState = {
  initiators: [],
  loadingIds: [],
  activatingIds: [],
  loading: false,
};
