export enum GtmID {
  InsightsSection = 'insights-section',
  InsightsOverviewTab = 'insights-overview-tab',
  InsightsSavedFiltersTab = 'insights-saved-filters-tab',

  SavedFiltersSaveButton = 'saved-filters-save-button',
  SavedFilters7Day = 'saved-filters-7-days',
  SavedFilters14Day = 'saved-filters-14-days',
  SavedFilters30Day = 'saved-filters-30-days',
  SavedFilters60Day = 'saved-filters-60-days',
  SavedFilters90Day = 'saved-filters-90-days',
  SavedFilters180Day = 'saved-filters-180-days',
  SavedFilters365Day = 'saved-filters-365-days',
  SavedFiltersCustomPeriod = 'saved-filters-custom-period',

  SavedFiltersRename = 'saved-filters-rename',
  SavedFiltersDelete = 'saved-filters-delete',

  FilterSentimentAll = 'filter-sentiment-all',
  FilterSentimentPositive = 'filter-sentiment-positive',
  FilterSentimentNeutral = 'filter-sentiment-neutral',
  FilterSentimentNegative = 'filter-sentiment-negative',

  FilterReviews = 'filter-reviews-toggle',
  FilterMessages = 'filter-messages-toggle',
  FilterSurveys = 'filter-surveys-toggle',

  FilterReviewRating = 'filter-reviews-rating',
  FilterReviewRegion = 'filter-reviews-region',

  SideFiltersToggleAll = 'side-filters-toggle-all',
  SideFilterToggleTags = 'filters-tags-toggle',
  SideFilterTogglePhrases = 'filters-phrases-toggle',
  SideFilterToggleSurveys = 'filters-surveys-toggle',
  SideFilterToggleFS = 'filters-fan-signals-toggle',

  TagFilterSelect = 'filter-tag-select',
  SurveyFilterSelect = 'filter-survey-select',
  FanSignalSelect = 'filter-select-fan-signal',
  PhraseSelect = 'filter-phrase-select',

  PhrasePin = 'filter-phrase-pin',
  PhraseUnpin = 'filter-phrase-unpin',
  PhraseHide = 'filter-phrase-hide',
  PhraseShow = 'filter-phrase-show',

  AddCardTag = 'add-card-tag',
  AddCardTagMostUsed = 'add-card-tag-most-used',
  RenameCardTag = 'rename-card-tag',
  DeleteCardTag = 'delete-card-tag',

  RenameAllTags = 'rename-all-tags',
  DeleteAllTags = 'delete-all-tags',

  // Interactions Section
  InteractionSection = 'interaction-section-top',
  InteractionOverview = 'interaction-overview',
  InteractionMessageCenter = 'interaction-message-center',
  InteractionLoveDialog = 'interaction-love-dialog',
  InteractionMultipleLoveDialog = 'interaction-multiple-love-dialogs',
  InteractionSurveys = 'interaction-surveys',
  InteractionSurveyLogic = 'interaction-survey-logic',
  InteractionNotes = 'interaction-notes',
  InteractionRatingDialog = 'interaction-rating-dialog',
  InteractionRatingDialogSdkObsolete = 'interaction-rating-dialog-sdk',

  InteractionOverviewEditLD = 'interaction-overview-edit-love-dialog',
  InteractionOverviewEditRD = 'interaction-overview-edit-rating-dialog',
  InteractionOverviewEditMC = 'interaction-overview-edit-message-center',
  InteractionOverviewSurveyCreate = 'interaction-overview-create-survey',
  InteractionOverviewSurveyManage = 'interaction-overview-manage-survey',
  InteractionOverviewSurveyStyling = 'interaction-overview-styling-survey',
  InteractionOverviewNoteCreate = 'interaction-overview-create-note',
  InteractionOverviewNoteManage = 'interaction-overview-manage-note',
  InteractionOverviewInitiatorManage = 'interaction-overview-manage-initiator',
  InteractionOverviewInitiatorCreateExternal = 'interaction-overview-create-initiator-external',

  InteractionSurveyNew = 'interaction-new-survey',
  InteractionSurveyTemplate = 'interaction-survey-from-template',
  InteractionSurveyDelete = 'interaction-survey-delete',
  InteractionSurveyArchive = 'interaction-survey-archive',
  InteractionSurveyUnArchive = 'interaction-survey-unarchive',
  InteractionSurveySelect = 'interaction-survey-select',
  InteractionSurveyLaunch = 'interaction-survey-launch',
  InteractionSurveyPause = 'interaction-survey-pause',

  InteractionNoteNew = 'interaction-note-new',
  InteractionNoteDelete = 'interaction-note-delete',
  InteractionNoteSelect = 'interaction-note-select',
  InteractionNoteLaunch = 'interaction-note-launch',
  InteractionNotePause = 'interaction-note-pause',

  InteractionLDLaunch = 'interaction-love-dialog-launch',
  InteractionLDPause = 'interaction-love-dialog-pause',

  InteractionRDLaunch = 'interaction-rating-dialog-launch',
  InteractionRDPause = 'interaction-rating-dialog-pause',

  InteractionLDNew = 'interaction-love-dialog-new',

  InteractionInitiatorDelete = 'interaction-initiator-delete',
  InteractionInitiatorSelect = 'interaction-initiator-select',
  InteractionInitiatorLaunch = 'interaction-initiator-launch',
  InteractionInitiatorPause = 'interaction-initiator-pause',

  // Settings Section
  SettingsIntegrationsWebhookCreate = 'settings-integrations-webhook-create',
  SettingsIntegrationsWebhookActivate = 'settings-integrations-webhook-activate',
  SettingsIntegrationsWebhookTest = 'settings-integrations-webhook-test',
  SettingsIntegrationsWebhookDelete = 'settings-integrations-webhook-delete',
  SettingsIntegrationsWebhookEditUrl = 'settings-integrations-webhook-edit-url',

  // Targeting
  RuleFsNewFan = 'target-rule-fan-signal-new-fan',
  RuleFsNewRisk = 'target-rule-fan-signal-new-risk',
  RuleFsShiftedFan = 'target-rule-fan-signal-shifted-fan',
  RuleFsShiftedRisk = 'target-rule-fan-signal-shifted-risk',
  RuleFsRepeatFan = 'target-rule-fan-signal-repeat-fan',
  RuleFsRepeatRisk = 'target-rule-fan-signal-repeat-risk',
  RuleFsNonResp = 'target-rule-fan-signal-non-respondent',
  RuleFsAddDetails = 'target-rule-fan-signal-add-details',

  None = '',
}
