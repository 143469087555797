/* eslint-disable arrow-body-style */
import { isEmpty } from 'ramda';
import { GetState, DispatchFunc } from 'src/reducers';
import { InitiatorsReducerAction, InitiatorItem } from 'src/reducers/initiators';
import { getCurrentAppId } from 'src/selectors/current_app';
import { InitiatorsService } from 'src/services';
import { InitiatorActions } from 'src/actions/initiators/initiators.action-types';
import { getInitiatorsList, getInitiatorsListIsLoading } from 'src/selectors/initiators.selectors';

type InitiatorDispatch = DispatchFunc<InitiatorsReducerAction>;

export const initiators = {
  saveInitiator: (initiator: InitiatorItem) => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: InitiatorActions.UPDATE_INITIATOR_PENDING, payload: { initiator } });

      return InitiatorsService.save(appId, initiator)
        .then((data) =>
          dispatch({
            type: InitiatorActions.UPDATE_INITIATOR_SUCCESS,
            payload: { initiator: data },
          }),
        )
        .catch((error) => {
          const errorMessage = Array.isArray(error.errors) ? error.errors : 'Failed to update a initiator';
          dispatch({ type: InitiatorActions.UPDATE_INITIATOR_ERROR, payload: { initiator, errorMessage }, error });
        });
    };
  },

  activateInitiator: (initiatorId: string, active: boolean) => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: InitiatorActions.ACTIVATE_INITIATOR_PENDING, payload: { initiatorId } });

      return InitiatorsService.activate(appId, initiatorId, active)
        .then((initiator) =>
          dispatch({
            type: InitiatorActions.ACTIVATE_INITIATOR_SUCCESS,
            payload: { initiator },
          }),
        )
        .catch((error) =>
          dispatch({ type: InitiatorActions.ACTIVATE_INITIATOR_ERROR, payload: { initiatorId }, error }),
        );
    };
  },

  bootstrapInitiators: () => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const state = getState();
      const list = getInitiatorsList(state);
      const isLoading = getInitiatorsListIsLoading(state);

      dispatch({ type: InitiatorActions.BOOTSTRAP_INITIATORS, payload: {} });

      if (!isLoading && isEmpty(list)) {
        return dispatch(initiators.fetchInitiators());
      }

      return Promise.resolve();
    };
  },

  fetchInitiators: () => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: InitiatorActions.GET_INITIATORS_PENDING, payload: {} });

      return InitiatorsService.fetchInitiators(appId)
        .then((data) =>
          dispatch({
            type: InitiatorActions.GET_INITIATORS_SUCCESS,
            payload: {
              initiators: data || [],
            },
          }),
        )
        .catch((error) => dispatch({ type: InitiatorActions.GET_INITIATORS_ERROR, error, payload: {} }));
    };
  },

  fetchInitiator: (initiatorId: string) => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());

      dispatch({ type: InitiatorActions.GET_INITIATOR_PENDING, payload: { initiatorId } });

      return InitiatorsService.fetchInitiator(appId, initiatorId)
        .then((initiator) =>
          dispatch({
            type: InitiatorActions.GET_INITIATOR_SUCCESS,
            payload: { initiator },
          }),
        )
        .catch((error) => dispatch({ type: InitiatorActions.GET_INITIATOR_ERROR, error, payload: { initiatorId } }));
    };
  },

  deleteInitiator: (initiatorId: string) => {
    return (dispatch: InitiatorDispatch, getState: GetState) => {
      const state = getState();
      const appId = getCurrentAppId(state);

      dispatch({ type: InitiatorActions.DELETE_INITIATOR_PENDING, payload: { initiatorId } });

      return InitiatorsService.deleteInitiator(appId, initiatorId)
        .then((data) => {
          dispatch({ type: InitiatorActions.DELETE_INITIATOR_SUCCESS, payload: { initiatorId, initiator: data } });
        })
        .catch((error) => dispatch({ type: InitiatorActions.DELETE_INITIATOR_ERROR, payload: { initiatorId }, error }));
    };
  },
};
